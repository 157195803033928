<template>
  <div style="background: #fff; padding-bottom: 40px; width: 100%">
    <div class="bid-log-btn" v-if="isBidLog && permission(['VIEW_BUSINESS_LOG'])">
      <el-button type="success" @click="bidIsShow = true">操作记录</el-button>
    </div>
    <el-form ref="form" :model="form" :rules="rules" style="margin-bottom: 20px">
      <!-- 必填内容 -->
      <el-descriptions direction="vertical" :column="3" border style="width: 100%">
        <template slot="title">
          <span>投标信息</span>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            标的名称
          </template>
          <el-form-item label-width="0px" prop="bidName" ref="bidName" style="width: 100%">
            <el-autocomplete
              :fetch-suggestions="businessQuerySearch"
              :disabled="!canWrite"
              v-model="form.bidName"
              @select="onbidNameSelect"
            >
            </el-autocomplete>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            采购方式
          </template>
          <el-form-item prop="bidType">
            <Dictionary
              v-if="canWrite"
              :disabled="!canWrite"
              :clearable="false"
              v-model="form.bidType"
              code="BID_TYPE"
              placeholder="请选择采购方式"
            />
            <span v-else>
              {{ form.bidType | dict(dictData.bidType) }}
            </span>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            业主单位
          </template>
          <el-form-item style="width: 100%" prop="bidOwnerUnit" ref="bidOwnerUnit">
            <el-input
              v-model="form.bidOwnerUnit"
              placeholder="请输入业主单位"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            代理招标单位
          </template>
          <el-form-item style="width: 100%" prop="bidAgency" ref="bidAgency">
            <el-input
              v-model="form.bidAgency"
              placeholder="请输入代理招标单位"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            是否重点关注
          </template>
          <el-form-item prop="bidHostEscort" ref="bidHostEscort">
            <el-radio-group
              v-model="form.bidHostEscort"
              :disabled="!canWrite"
              @change="on_ZP_Change"
            >
              <el-radio label="IS_FLAG">是</el-radio>
              <el-radio label="NOT_FLAG">否 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            报名截止时间
          </template>
          <el-form-item prop="bidRegistrationDeadline" ref="bidRegistrationDeadline">
            <el-date-picker
              v-model="form.bidRegistrationDeadline"
              type="datetime"
              placeholder="选择报名截止时间"
              value-format="timestamp"
              :disabled="!canWrite"
              default-time="23:59:59"
            >
            </el-date-picker>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            开标时间
          </template>
          <el-form-item prop="bidOpeningTime" ref="bidOpeningTime">
            <el-date-picker
              v-model="form.bidOpeningTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择开标时间"
              :disabled="!canWrite"
              default-time="09:30:00"
            >
            </el-date-picker>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            开标地点
          </template>
          <el-form-item prop="bidOpeningLocation" style="width: 100%">
            <el-input
              v-model.trim="form.bidOpeningLocation"
              placeholder="请输入开标地点"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>
            开标方式
          </template>
          <el-form-item prop="bidOpeningMethod" style="width: 100%">
            <Dictionary
              style="width: 100%"
              v-model="form.bidOpeningMethod"
              class="ipt_width"
              code="BID_OPENING_METHOD"
              placeholder="请选择开标方式"
            />
          </el-form-item>
        </el-descriptions-item>
      </el-descriptions>

      <!-- 折叠内容 -->
      <el-collapse v-model="collapseActive" accordion>
        <el-collapse-item name="1">
          <template slot="title">
            <div class="collapse-title-box">
              <i class="header-icon el-icon-info" style="padding-right: 5px"></i>展开更多投标信息
            </div>
          </template>
          <div>
            <el-descriptions direction="vertical" :column="4" border style="width: 100%">
              <el-descriptions-item>
                <template slot="label"> 信息来源</template>
                <el-form-item
                  label-width="0px"
                  prop="bidSource"
                  ref="bidSource"
                  style="width: 100%"
                >
                  <el-input
                    v-model="form.bidSource"
                    placeholder="请输入信息来源"
                    :disabled="!canWrite"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- <el-descriptions-item>
                <template slot="label"> 中标金额（元） </template>
                <el-form-item prop="bidWinningAmount" style="width: 100%">
                  <el-input
                    placeholder="请输入中标金额"
                    @input="inputFn('bidWinningAmount')"
                    @focus="focusFn('bidWinningAmount')"
                    @blur="blurFn('bidWinningAmount')"
                    v-model="form.bidWinningAmount"
                    :disabled="!canWrite"
                  >
                  </el-input
                ></el-form-item>
              </el-descriptions-item> -->

              <el-descriptions-item :span="2">
                <template slot="label"> 商务项</template>
                <el-form-item
                  label-width="0px"
                  prop="bidBusinessItems"
                  ref="bidBusinessItems"
                  style="width: 100%"
                >
                  <el-input
                    v-model="form.bidBusinessItems"
                    placeholder="请输入商务项"
                    :disabled="!canWrite"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 报价方式</template>
                <el-form-item
                  label-width="0px"
                  prop="bidQuotationMethod"
                  ref="bidQuotationMethod"
                  style="width: 100%"
                >
                  <el-input
                    v-model="form.bidQuotationMethod"
                    placeholder="请输入报价方式"
                    :disabled="!canWrite"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4">
                <template slot="label"> 招标文件（附件上传） </template>
                <el-form-item prop="bidFile" style="width: 100%">
                  <UploadList
                    :disabled="!canWrite"
                    fileCode="user_fj"
                    :value="form.bidManagementFileAddList ? form.bidManagementFileAddList : []"
                    @change="change($event)"
                    :staffId="form.id"
                    :tableHeight="null"
                  ></UploadList>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="4">
                <template slot="label"> 备注 </template>
                <el-form-item
                  label-width="0px"
                  prop="bidRemark"
                  ref="bidRemark"
                  style="width: 100%"
                >
                  <el-input
                    type="textarea"
                    v-model="form.bidRemark"
                    placeholder="请输入备注"
                    :disabled="!canWrite"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 投标截止时间 </template>
                <el-form-item prop="bidSubmissionDeadline" ref="bidSubmissionDeadline">
                  <el-date-picker
                    v-model="form.bidSubmissionDeadline"
                    type="date"
                    placeholder="选择投标截止时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 开标介质有效期</template>
                <el-form-item
                  label-width="0px"
                  prop="bidOpeningMediaValidity"
                  ref="bidOpeningMediaValidity"
                  style="width: 100%"
                >
                  <el-date-picker
                    v-model="form.bidOpeningMediaValidity"
                    type="date"
                    placeholder="选择开标介质有效期"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 招标代理服务费（元） </template>
                <el-form-item prop="biddingAgencyServiceFee" style="width: 100%">
                  <el-input
                    placeholder="请输入招标代理服务费"
                    @input="inputFn('biddingAgencyServiceFee')"
                    @focus="focusFn('biddingAgencyServiceFee')"
                    @blur="blurFn('biddingAgencyServiceFee')"
                    v-model="form.biddingAgencyServiceFee"
                    :disabled="!canWrite"
                  >
                  </el-input
                ></el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 文件交付时间 </template>
                <el-form-item prop="bidDocumentDeliveryTime" ref="bidDocumentDeliveryTime">
                  <el-date-picker
                    v-model="form.bidDocumentDeliveryTime"
                    type="date"
                    placeholder="选择文件交付时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 文件交付地点 </template>
                <el-form-item prop="bidDocumentDeliveryLocation" style="width: 100%">
                  <el-input
                    v-model.trim="form.bidDocumentDeliveryLocation"
                    placeholder="请输入文件交付地点"
                    :disabled="!canWrite"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 报价的最迟时间 </template>
                <el-form-item prop="bidQuotationDeadline" ref="bidQuotationDeadline">
                  <el-date-picker
                    v-model="form.bidQuotationDeadline"
                    type="date"
                    placeholder="选择时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 合作单位材料提供的最迟时间 </template>
                <el-form-item prop="bidPartnerMaterialDeadline" ref="bidPartnerMaterialDeadline">
                  <el-date-picker
                    v-model="form.bidPartnerMaterialDeadline"
                    type="date"
                    placeholder="选择时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 公示期结束时间 </template>
                <el-form-item
                  prop="bidPublicAnnouncementEndTime"
                  ref="bidPublicAnnouncementEndTime"
                >
                  <el-date-picker
                    v-model="form.bidPublicAnnouncementEndTime"
                    type="date"
                    placeholder="选择时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 授权代表材料提供的最迟时间 </template>
                <el-form-item prop="bidAuthorizedDeadline" ref="bidAuthorizedDeadline">
                  <el-date-picker
                    v-model="form.bidAuthorizedDeadline"
                    type="date"
                    placeholder="选择时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label"> 标书技术方案书写人方案最迟的给予时间 </template>
                <el-form-item
                  prop="bidTechnicalProposalDeadline"
                  ref="bidTechnicalProposalDeadline"
                >
                  <el-date-picker
                    v-model="form.bidTechnicalProposalDeadline"
                    type="date"
                    placeholder="选择时间"
                    value-format="timestamp"
                    :disabled="!canWrite"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item :span="4">
                <template slot="label"> 投标描述 </template>
                <el-form-item
                  label-width="0px"
                  prop="bidDescription"
                  ref="bidDescription"
                  style="width: 100%"
                >
                  <el-input
                    type="textarea"
                    v-model="form.bidDescription"
                    placeholder="请输入投标描述"
                    :disabled="!canWrite"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>

    <!-- 商务列表 -->
    <!-- <el-descriptions style="width: 100%; margin-bottom: 20px" border>
      <template slot="title">
        <span>商务信息</span>
      </template>
      <el-descriptions-item labelStyle="width:100px;" :span="1">
        <template slot="label">商务名称</template>
        <el-select
          style="width: 50%"
          v-model="form.businessName"
          placeholder="请选择商务"
          clearable
          filterable
          value-key="id"
          :disabled="!canWrite && editType !== 'claim'"
          @change="onBusinessNameChange"
        >
          <el-option
            v-for="item in businessNameList"
            :key="item.id"
            :label="item.businessName"
            :value="item"
          ></el-option>
        </el-select>
      </el-descriptions-item>
    </el-descriptions> -->
    <div style="width: 100%">
      <div class="public-title">商务信息</div>
      <div class="table-content">
        <el-table :data="businessList" id="table" border :key="businessKey">
          <el-table-column
            align="center"
            prop="businessName"
            min-width="280"
            :show-overflow-tooltip="false"
            label="商务名称"
          ></el-table-column>
          <el-table-column
            align="center"
            min-width="180"
            :show-overflow-tooltip="false"
            prop="ownerUnit"
            label="业主单位"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="contacts"
            label="经办人"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="contactsPhone"
            label="经办人电话"
            width="110"
          ></el-table-column>
          <!-- <el-table-column
            align="center"
            prop="initiatorName"
            label="填报人"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="principalUserName"
            label="实际商务负责人"
            sortable
            width="150"
          ></el-table-column> -->
          <el-table-column align="center" width="110" sortable prop="createdDate" label="申请时间">
            <template slot-scope="scope">
              {{ scope.row.createdDate | dateFormat }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="businessType"
            sortable
            label="商务类型"
            width="120"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.businessType | dict(dictData.businessType) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="status"
            label="状态"
            sortable
            width="160"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.status | dict(dictData.businessStauts) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="100"
            v-if="canWrite || editType == 'claim'"
            fixed="right"
          >
            <template slot="header">
              <div>
                <i class="iconfont iconadd-circle" @click="addBusiness" v-show="canAddBusiness"></i>
              </div>
            </template>
            <template slot-scope="scope">
              <i class="iconfont iconerror" @click="deleteBusiness(scope.$index, scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 费用管理 -->
    <div style="width: 100%">
      <div class="public-title">费用管理</div>
      <el-tabs v-model="bidCostName" type="card" @tab-click="onHandleClick">
        <el-tab-pane label="公共费用付款申请" name="paymentApplications"> </el-tab-pane>
        <el-tab-pane label="公共费用报销" name="paymentExpenses"></el-tab-pane>
        <el-tab-pane label="项目付款申请" name="projectApplications"></el-tab-pane>
        <el-tab-pane label="项目报销" name="projectExpenses"></el-tab-pane>
        <el-tab-pane label="商务外协费用" name="businessPayments"></el-tab-pane>
        <el-tab-pane label="商务报销" name="businessExpenses"> </el-tab-pane>
      </el-tabs>
      <el-table :data="costList" border>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              v-show="scope.row.paymentDetail?.length > 0"
              :data="scope.row.paymentDetail"
              style="margin-left: 59px; width: 93%"
              max-height="400"
              border
            >
              <el-table-column
                prop="typeOne"
                :show-overflow-tooltip="false"
                min-width="150"
                label="费用科目一"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="typeTwo"
                :show-overflow-tooltip="false"
                min-width="150"
                label="费用科目二"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="typeThree"
                :show-overflow-tooltip="false"
                min-width="150"
                label="费用科目三"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="cost" min-width="110" label="金额（元）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.cost | applyAmount }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          prop="expenseNumber"
          label="单据编号"
          align="center"
          width="175"
        ></el-table-column>
        <el-table-column
          prop="applicantName"
          sortable
          label="申请人"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deptName"
          sortable
          width="130"
          label="费用所属部门"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="collectionUnit"
          sortable
          min-width="280"
          :show-overflow-tooltip="false"
          label="收款单位"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="sumCost" width="120" sortable label="申请金额" align="center">
          <template slot-scope="scope">
            {{ scope.row.sumcost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="projectBudget" width="120" sortable label="申请时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.createdDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="statusName"
          :show-overflow-tooltip="false"
          width="150"
          label="流程进度"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column align="center" width="100" v-if="canWrite" fixed="right">
          <template slot="header">
            <i class="iconfont iconadd-circle" @click="addPublicPayment"></i>
          </template>
          <template slot-scope="scope">
            <i class="iconfont iconerror" @click="deletePublicPayment(scope.$index, scope.row)"></i>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <ChoicePublicPayment
      :width="70"
      v-if="isPublicPaymentDlgShow"
      :isShow.sync="isPublicPaymentDlgShow"
      @saveFn="choicePublicPayment"
      :pitchOnList="pitchOnList"
      :deleteIds="deleteIds"
      :bidCostType="bidCostName"
    ></ChoicePublicPayment>

    <ChoiceBusiness
      :width="70"
      v-if="isBusinessDlgShow"
      :isShow.sync="isBusinessDlgShow"
      @choiceDataChange="choiceBusiness"
      @closeBusinessDialog="onBusinessDialogClosed"
    ></ChoiceBusiness>

    <!-- 操作记录 -->
    <BidLog
      v-if="bidIsShow"
      :isShow.sync="bidIsShow"
      :options="form"
      :business="BUSINESS"
      :paymentApplications="PAYMENTAPPLICATIONS"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { bidRules } from '@/util/rules.js'
import { delcommafy } from '@/util/jsencrypt'
export default {
  name: 'BidInfo',
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    ChoicePublicPayment: () => import('@/components/bid/ChoicePublicPayment.vue'),
    ChoiceBusiness: () => import('@/components/bid/ChoiceBusiness.vue'),
    BidLog: () => import('@/components/bid/BidLog.vue'),
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  data() {
    // 标名称自定义校验规则
    var validatebid = (rule, value, callback) => {
      if (!this.disabled) {
        if (value == '') {
          callback(new Error('请输入标名称'))
        } else {
          let flag = this.bidNameList.some(item => item.value == value)
          if (flag && value !== this.selectBidName) {
            // 说明有重复的标名称，回显校验提示内容
            callback(new Error('当前标名称与历史标名称重复，请注意修改'))
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    }
    return {
      //页面双向绑定用到的数据
      form: {
        bidName: '',
        bidType: '',
        bidOwnerUnit: '',
        bidAgency: '',
        bidHostEscort: '',
        bidRegistrationDeadline: '',
        bidOpeningTime: '',
        bidOpeningLocation: '',
        bidOpeningMethod: '',
        bidSource: '',
        bidWinningAmount: '',
        bidBusinessItems: '',
        bidQuotationMethod: '',
        bidRemark: '',
        bidSubmissionDeadline: '',
        bidOpeningMediaValidity: '',
        biddingAgencyServiceFee: '',
        bidDocumentDeliveryTime: '',
        bidDocumentDeliveryLocation: '',
        bidQuotationDeadline: '',
        bidPartnerMaterialDeadline: '',
        bidPublicAnnouncementEndTime: '',
        bidAuthorizedDeadline: '',
        bidTechnicalProposalDeadline: '',
        bidDescription: '',
        bidStatus: '',
        businessId: '',
        businessName: '',
      },
      selectBidName: '',
      oldBidForm: {}, //记录原始数据
      rules: { ...bidRules, bidName: [{ validator: validatebid, trigger: 'change' }] },
      collapseActive: '',
      dictData: {
        businessType: [],
        businessStauts: [],
        bidType: [],
      },

      isPublicPaymentDlgShow: false, //公共费用弹框显示隐藏
      pitchOnList: [], //当选选中的公共费用列表
      deleteIds: [], //被删除有id的公共费用
      publicPaymentList: [],
      publicPaymentDeleteList: [], //被删除的付款id

      isBusinessDlgShow: false,
      businessKey: 0,
      businessList: [],
      businessListDeleteList: [], //被删除的付款id

      bidIsShow: false,
      bidCostName: 'paymentApplications',
      paymentExpenses: [],
      projectApplications: [],
      projectExpenses: [],
      businessExpenses: [],
      businessPayments: [],
      list: [],
      bidNameList: [],
    }
  },
  props: {
    //详情页面传入的数据
    formData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //当前页面输入框输入状态
    editType: {
      type: String,
      default: '',
    },
    isBidLog: {
      //操作日志
      type: Boolean,
      default: false,
    },
  },
  filters: {
    applyAmount(n) {
      return Number(n).toLocaleString()
    },
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('bid', ['ZP_Members']),
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'bidEdit':
          return false
        default:
          return false
      }
    },
    //是否显示添加商务按钮
    canAddBusiness() {
      this.businessKey++
      if (this.businessList.length > 0) {
        return false
      }
      return true
    },

    //商务信息
    BUSINESS() {
      return this.oldBidForm.business || {}
    },

    //公共费用
    PAYMENTAPPLICATIONS() {
      return this.oldBidForm.paymentApplications || []
    },
    costList() {
      let arr = []
      if (this.bidCostName == 'businessExpenses' && this.businessExpenses) {
        arr = this.businessExpenses
      } else if (this.bidCostName == 'businessPayments' && this.businessPayments) {
        arr = this.businessPayments
      } else if (this.bidCostName == 'projectExpenses' && this.projectExpenses) {
        arr = this.projectExpenses
      } else if (this.bidCostName == 'projectApplications' && this.projectApplications) {
        arr = this.projectApplications
      } else if (this.bidCostName == 'paymentExpenses' && this.paymentExpenses) {
        arr = this.paymentExpenses
      } else if (this.bidCostName == 'paymentApplications' && this.publicPaymentList) {
        arr = this.publicPaymentList
      }
      return arr
    },
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.id) {
          this.form = val.deepClone()
          this.oldBidForm = val.deepClone()
          this.selectBidName = this.oldBidForm?.bidName
          if (val.business) {
            this.businessList = []
            this.businessList.push(val.business)
          }
          this.oldBidForm.bidWinningAmount = this.oldBidForm.bidWinningAmount || ''
          this.oldBidForm.biddingAgencyServiceFee = this.oldBidForm.biddingAgencyServiceFee || ''
          this.oldBidForm.businessId = this.oldBidForm.businessId || ''
          this.form.bidWinningAmount = this.form.bidWinningAmount || ''
          this.form.biddingAgencyServiceFee = this.form.biddingAgencyServiceFee || ''
          this.form.bidManagementFileAddList =
            this.oldBidForm.bidManagementInviteFiles?.map(item => {
              return {
                ...item,
                staffId: item.fkId,
                attachmentName: item.fileName,
                attachmentPath: item.filePath,
              }
            }) || []
          // this.publicPaymentList = [...(val.paymentApplications || [])]
          if (val.costManagementList) {
            const arr = JSON.parse(JSON.stringify(val.costManagementList))
            arr.forEach(item => {
              for (const key in item) {
                if (Object.hasOwnProperty.call(item, key)) {
                  const e = item[key]
                  if (key == 'businessExpenses') {
                    this.businessExpenses = e
                  } else if (key == 'businessPayments') {
                    this.businessPayments = e
                  } else if (key == 'projectExpenses') {
                    this.projectExpenses = e
                  } else if (key == 'projectApplications') {
                    this.projectApplications = e
                  } else if (key == 'paymentExpenses') {
                    this.paymentExpenses = e
                  } else if (key == 'paymentApplications') {
                    this.publicPaymentList = e
                  }
                }
              }
            })
          }
        }
      },
    },
  },
  methods: {
    ...mapMutations('bid', ['SET_ZP_Members']),
    change(r) {
      this.form.bidManagementFileAddList = [...r]
    },
    businessQuerySearch(queryString, cb) {
      let bidNameList = this.bidNameList
      let results = queryString ? this.bidNameCreateFilter(queryString) : bidNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    bidNameCreateFilter(queryString) {
      let results = this.bidNameList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onbidNameSelect(e) {
      if (e) {
        this.form.bidName = e.value
        this.$refs.form.validateField('bidName')
      }
      this.$forceUpdate()
    },

    onHandleClick(val) {},
    /** 值改变触发 "申请金额" 文本框 **/
    inputFn(e) {
      this.form[e] = String(this.form[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusFn(e) {
      this.form[e] = String(this.form[e]).replace(/,/g, '')
      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurFn(e) {
      this.form[e] = Number(this.form[e]).toLocaleString()
      this.$forceUpdate()
    },

    init() {
      this.$api.bid
        .nameListNoPage()
        .then(res => {
          if (res) {
            this.bidNameList = res.data.map(item => {
              return { value: item }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('ALL_BUSINESSMANAGE_TYPE', true)
        .then(res => {
          this.dictData.businessStauts = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BID_TYPE', true)
        .then(res => {
          this.dictData.bidType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    addPublicPayment(key) {
      this.isPublicPaymentDlgShow = true
    },

    choicePublicPayment(e) {
      e.forEach(v => {
        if (!this.pitchOnList.find(item => item.id === v.id)) {
          // this.publicPaymentList.push({
          //   applicantName: v.applicantName,
          //   collectionUnit: v.collectionUnit,
          //   companyType: v.companyType,
          //   createdDate: v.createdDate,
          //   deptName: v.deptName,
          //   deptId: v.deptId,
          //   expenseNumber: v.expenseNumber,
          //   sumcost: v.sumcost,
          //   taskDate: v.taskDate,
          //   paymentDetail: v.paymentDetail,
          //   status: v.status,
          //   statusName: v.statusName,
          //   id: v.id,
          // })
          const obj = {
            applicantName: v.applicantName,
            collectionUnit: v.collectionUnit,
            companyType: v.companyType,
            createdDate: v.createdDate,
            deptName: v.deptName,
            deptId: v.deptId,
            expenseNumber: v.expenseNumber,
            sumcost: v.sumcost,
            taskDate: v.taskDate,
            paymentDetail: v.paymentDetail,
            status: v.status,
            statusName: v.statusName,
            id: v.id,
          }
          if (this.bidCostName == 'businessExpenses' && this.businessExpenses) {
            this.businessExpenses.push(obj)
          } else if (this.bidCostName == 'businessPayments' && this.businessPayments) {
            this.businessPayments.push(obj)
          } else if (this.bidCostName == 'projectExpenses' && this.projectExpenses) {
            this.projectExpenses.push(obj)
          } else if (this.bidCostName == 'projectApplications' && this.projectApplications) {
            this.projectApplications.push(obj)
          } else if (this.bidCostName == 'paymentExpenses' && this.paymentExpenses) {
            this.paymentExpenses.push(obj)
          } else if (this.bidCostName == 'paymentApplications' && this.publicPaymentList) {
            this.publicPaymentList.push(obj)
          }
          this.pitchOnList.push(v)
        }
      })
    },

    deletePublicPayment(index, row) {
      if (this.bidCostName == 'businessExpenses') {
        this.businessExpenses.splice(index, 1)
      } else if (this.bidCostName == 'businessPayments') {
        this.businessPayments.splice(index, 1)
      } else if (this.bidCostName == 'projectExpenses') {
        this.projectExpenses.splice(index, 1)
      } else if (this.bidCostName == 'projectApplications') {
        this.projectApplications.splice(index, 1)
      } else if (this.bidCostName == 'paymentExpenses') {
        this.paymentExpenses.splice(index, 1)
      } else if (this.bidCostName == 'paymentApplications') {
        this.publicPaymentList.splice(index, 1)
      }
      this.pitchOnList.splice(index, 1)

      if (this.form.paymentApplications && this.form.paymentApplications.length > 0) {
        const deleteRes = this.form.paymentApplications.find(item => item.id == row.id)
        if (deleteRes) {
          //说明存在绑定的值
          if (!this.publicPaymentDeleteList.find(item => item == deleteRes.id)) {
            this.publicPaymentDeleteList.push(deleteRes.id)
            this.deleteIds.push(deleteRes)
          }
        }
      }
    },

    addBusiness() {
      this.isBusinessDlgShow = true
    },

    choiceBusiness(e) {
      this.businessList.push(e)
      this.isBusinessDlgShow = false
    },

    onBusinessDialogClosed(e) {
      this.isBusinessDlgShow = e
    },

    deleteBusiness(index, row) {
      this.businessList.splice(index, 1)
    },

    // // 暂存
    // temAdd() {
    //   // 格式化金额
    //   this.form.bidWinningAmount = Number.parseInt(delcommafy(this.form.bidWinningAmount))
    //   this.form.biddingAgencyServiceFee = Number.parseInt(
    //     delcommafy(this.form.biddingAgencyServiceFee)
    //   )
    //   const req = {
    //     bidManagementInfoReq: this.form,
    //     paymentApplicationList: this.publicPaymentList.map(item => item.id),
    //   }
    //   req.bidManagementInfoReq.bidStatus = 'temporary'
    //   return req
    // },

    // 提交
    save() {
      let addPublicPayment = this.publicPaymentList?.map(item => item.id) || []
      let deletePublicPayment = this.publicPaymentDeleteList.filter(
        element => !addPublicPayment.includes(element)
      )
      let req = {}
      let isCheck = false
      this.$refs.form.validate(valid => {
        if (valid) {
          const newIds = new Set(this.form.bidManagementFileAddList?.map(item => item.id))
          this.form.bidManagementFileDeleteList = this.oldBidForm.bidManagementInviteFiles
            ?.filter(item => !newIds.has(item.id))
            ?.map(item => item.id)
          // 格式化金额
          if (this.form.bidWinningAmount) {
            this.form.bidWinningAmount = Number(delcommafy(this.form.bidWinningAmount)) || 0
          }
          if (this.form.biddingAgencyServiceFee) {
            this.form.biddingAgencyServiceFee =
              Number(delcommafy(this.form.biddingAgencyServiceFee)) || 0
          }
          // 关联商务
          if (this.businessList.length > 0) {
            this.form.businessId = this.businessList[0].id
          } else {
            this.form.businessId = ''
          }
          // 操作记录
          const editArr = this.selectEditArr(addPublicPayment)
          req = {
            bidManagementInfoReq: this.form,
            // paymentApplicationList: addPublicPayment,
            // delPaymentApplicationList: deletePublicPayment,
          }
          req.bidManagementInfoReq.bidStatus = 'bidding'
          req.bidManagementInfoReq.changeFieldList = editArr
          isCheck = true
        } else {
          this.$message.error('请填写必填内容')
          isCheck = false
        }
      })
      if (isCheck) {
        return req
      } else {
        return 'warning'
      }
    },

    // 处理变更字段
    selectEditArr(newPublicPaymentIds) {
      let editArr = []
      // 不需要操作记录的字段
      let ignoreKey = [
        'business',
        'costManagementList',
        'bidManagementFiles',
        'bidManagementCost',
        'bidSecurityDeposits',
        'bidMembers',
        'bidAnalysisResp',
        'bidUnitPriceReq',
      ]
      // 基本信息
      Object.keys(this.oldBidForm).forEach(oldKey => {
        let old = this.oldBidForm[oldKey]
        if (typeof this.oldBidForm[oldKey] === 'string') {
          old = this.oldBidForm[oldKey].trim()
        }
        Object.keys(this.form).forEach(key => {
          let e = this.form[key]
          if (typeof this.form[key] === 'string') {
            e = this.form[key].trim()
          }
          if (
            oldKey === key &&
            old !== e &&
            !ignoreKey.includes(oldKey) &&
            !ignoreKey.includes(key)
          ) {
            editArr.push(key)
          }
        })
      })
      // 判断公共费用是否修改
      // let oldIds = this.oldBidForm.paymentApplications?.map(old => old.id) || []
      // if (oldIds.length !== newPublicPaymentIds.length) {
      //   editArr.push('paymentApplications')
      // } else {
      //   oldIds.forEach((item, index) => {
      //     if (item !== newPublicPaymentIds[index] && !editArr.includes('paymentApplications')) {
      //       editArr.push('paymentApplications')
      //     }
      //   })
      // }
      return editArr
    },

    async on_ZP_Change(e) {
      const res = await this.$api.bid.getMemberList({
        bidHostEscort: e,
      })
      if (res.data && res.data.length > 0) {
        res.data.forEach(item => {
          if (item.id) {
            delete item.id
          }
        })
        this.SET_ZP_Members(res.data)
      }
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.el-collapse {
  width: 100%;
  .el-collapse-item__header {
    background-color: #fdf6ec;
    border: 1px solid #ebeef5;
  }
  .el-collapse-item__content {
    padding-bottom: 0px;
  }
  .collapse-title-box {
    color: #e6a23c;
    padding: 12px 10px;
    line-height: 1.5;
    font-size: 14px;
  }
}

.public-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.bid-log-btn {
  padding-left: calc(100% - 105px);
}
</style>
